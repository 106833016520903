import axios from 'axios'
import api from '@/api'
import { User } from '@/User'

const data = {
  pagination: {},
  data: [],
  default: ''
}
const ebsEmails = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEmails: state => {
      return state.data
    },
    getEmailsMappedLabelValue: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          label: item.title + item.id,
          customLabel: item.title + ( !item.cloned ? ' (default)' : '' ),
          value: item.id,
          identifier: item.identifier
        }
      })
    },
    getEmailsMappedIdNameStatus: state => {
      const rawItems = state.data
      if (!rawItems.length) return []
      return rawItems.map((item) => {
        return {
          id: item.id,
          name: item.name,
          status: item.status
        }
      })
    },
    getEmailsPagination: state => {
      return state.pagination
    }
  },
  mutations: {
    GET_EMAILS (state, payload) {
      let payloadData = JSON.parse(JSON.stringify(payload))

      state.data = payloadData
      state.default = JSON.parse(JSON.stringify(payloadData))
    },
    CLEAR_EMAILS (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    SET_EMAILS_PAGINATION (state, payload) {
      state.pagination = JSON.parse(JSON.stringify(payload))
    },
    RESET_EMAILS (state) {
      state.data = JSON.parse(JSON.stringify(state.default))
    },
    CLEAR_EMAILS_PAGINATION (state) {
      state.pagination = JSON.parse(JSON.stringify(data.pagination))
    }
  },
  actions: {
    getEmails ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const user = new User()
        const queryUrlParams = data && data.queryUrlParams ? data.queryUrlParams : '?pageSize=9999'
        const requestData = data && data.requestData ? data.requestData : ''
        const filterTriggerEvent = requestData && requestData.triggerEvent ? `&filters[triggerEvent]=${requestData.triggerEvent}` : ''

        let categoryFiltersParams = ''
        let categoryFiltersArr = []

        if (
          user.isEventCoordinator() ||
          user.isInfrastructureManager() ||
          user.isUserAdministrator() ||
          user.isSiteAdmin() ||
          user.isAdmin() ||
          user.isLearningExperienceAdministrator() ||
          user.isCourseCreator()
        ) {
          if (user.hasAnyCustomerService(['EBS', 'DW', 'LMS'])) {
            categoryFiltersArr.push('reports')
          }
          if (user.hasAnyCustomerService(['EBS'])) {
            categoryFiltersArr.push('events')
          }
          if (user.hasAnyCustomerService(['DW'])) {
            categoryFiltersArr.push('dw')
          }
          if (user.hasAnyCustomerService(['LMS'])) {
            categoryFiltersArr.push('courses')
          }
        }

        if (user.isTokenFromNewLms()) {
          if (user.isUserAdministrator()) {
            categoryFiltersArr.push('users')
          }
        }

        if (user.isTokenFromOldLms()) {
          if (
            user.isEventCoordinator() ||
            user.isInfrastructureManager() ||
            user.isDigitalWorkbookAdmin()
          ) {
            categoryFiltersArr.push('blue')
            categoryFiltersArr.push('dw')
          }
        }

        if (categoryFiltersArr.length && !queryUrlParams.includes('filters[category]')) {
          categoryFiltersParams = `&filters[category]=${categoryFiltersArr.join()}`
        }

        commit('CLEAR_EMAILS')
        commit('CLEAR_EMAILS_PAGINATION')

        axios({
          method: 'get',
          url: `${api.emailservice}/domain/emails${queryUrlParams}${categoryFiltersParams}${filterTriggerEvent}`
        }).then(response => {
          commit('GET_EMAILS', response.data.data)
          commit('SET_EMAILS_PAGINATION', response.data.pagination)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    resetEmails ({ commit }) {
      commit('RESET_EMAILS')
    }
  }
}

export default ebsEmails
